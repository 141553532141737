<template>
  <div>
    <core-toolbar>PRODUTOS</core-toolbar>

    <v-container class="pt-10">
      <v-row>
        <v-col
          v-for="product in products"
          :key="product.id"
          cols="6"
          sm="4"
          md="3"
        >
          <v-hover>
            <template v-slot:default="{ hover }">
              <v-card
                @click="openDialog(product.id)"
              >
                <v-img
                  :src="imageName(product.image)"
                  :height="!product.image ? '10rem' : null"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        v-if="product.image"
                        indeterminate
                        color="#3E4095"
                      />
                    </v-row>
                  </template>
                </v-img>

                <v-divider class="mx-4" />

                <v-card-title class="pb-0 text-h4 huwe--text">
                  {{ product.name }}
                </v-card-title>

                <v-card-actions>
                  <v-btn
                    color="#3E4095"
                    dark
                    text
                  >
                    <b>Saiba mais</b>
                    <v-icon
                      right
                      v-text="'mdi-plus'"
                    />
                  </v-btn>
                </v-card-actions>

                <v-fade-transition>
                  <v-overlay
                    v-if="hover"
                    absolute
                    color="black"
                    opacity="0.1"
                  />
                </v-fade-transition>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-progress-linear
            v-if="loading"
            indeterminate
            color="#3E4095"
          />
        </v-col>
      </v-row>

      <v-row
        justify="center"
        class="mt-8 mb-2"
      >
        <v-pagination
          v-model="pagination.page"
          :length="paginationLength"
          color="#3E4095"
          circle
        />
      </v-row>
    </v-container>

    <dialog-product
      v-model="showDialogProduct"
      :product-id="productId"
    />
  </div>
</template>

<script>
  import productsApi from '@/api/products'

  export default {
    components: {
      CoreToolbar: () => import('@/components/site/core/CoreToolbar'),
      DialogProduct: () => import('@/components/site/dialog/DialogProduct'),
    },

    data: () => ({
      loading: false,
      showDialogProduct: false,
      count: 0,
      products: [],
      productId: null,
      pagination: {
        page: 1,
        itemsPerPage: 12,
      },
    }),

    computed: {
      paginationLength () {
        return Math.ceil(this.count / this.pagination.itemsPerPage)
      },
    },

    watch: {
      'pagination.page' () {
        this.loadProducts()
      },
    },

    mounted () {
      this.loadProducts()
    },

    methods: {
      async loadProducts () {
        try {
          this.loading = true

          const response = await productsApi.list({
            limit: this.pagination.itemsPerPage,
            offset:
              this.pagination.page * this.pagination.itemsPerPage -
              this.pagination.itemsPerPage,
          })

          this.products = response.data.products
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      openDialog (productId) {
        this.productId = productId
        this.showDialogProduct = true
      },

      imageName (name) {
        return `${process.env.VUE_APP_API}public/products/${name}`
      },
    },
  }
</script>
